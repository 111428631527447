@import "@able/react/dist/able-react-without-LG.min.css";
@import "@able/web/dist/able-web.css";
html,
body,
#ppa,
#ppa > div {
  height: 100%;
  background-color: #F8F8F8 !important;
}
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 1290px !important;
  }
}
@media only screen and (max-width: 767px) {
  .boost-ppa {
    display: flex;
    flex-direction: column;
  }
}
.ppa-container > div {
  padding-bottom: 120px;
  background-color: #F8F8F8 !important;
}
@media only screen and (min-width: 48rem) {
  .ppa-container > div {
    background-color: #F8F8F8 !important;
  }
}
.block {
  display: block !important;
}
.theme {
  font-weight: normal;
}
.debugger-button {
  background: transparent !important;
  border: 0px;
  font-size: 25px;
  float: right;
}
.debugger-button:focus {
  outline-color: transparent;
}
.error-icon {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-right: 5px;
  left: 2px;
  top: 2px;
}
.error-msg {
  color: #282828;
  font-size: 14px;
  font: 400 0.875rem/1.5 Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.01125rem !important;
}
.form-element {
  position: relative;
  margin: 10px 0;
}
.form-element label {
  display: block;
  margin: 10px 0;
}
.modal-dialog {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}
.ReactModal__Overlay {
  z-index: 9999 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.ReactModal__Body--open {
  overflow: hidden;
  background-color: #f8f8f8 !important;
}
.ReactModal__Content {
  margin-top: 150px !important;
  display: inherit !important;
  min-height: auto !important;
}
.ReactModal__Content .modal-body {
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  background-color: #f8f8f8 !important;
}
.ReactModal__Content .modal-header-no-border {
  background-color: #f8f8f8 !important;
}
.ReactModal__Content .modal-footer {
  background-color: #f8f8f8 !important;
}
.ReactModal__Content .modal-content {
  background-color: #f8f8f8 !important;
}
.button-as-link {
  background: none!important;
  color: #1964c8;
  border: none;
  padding: 0!important;
  font: inherit;
  cursor: pointer;
  text-decoration: underline !important;
}
.esim-wrapper .cancel-activation-link {
  cursor: pointer;
  margin-left: 0px !important;
  margin-top: 40px !important;
}
.esim-wrapper .cancel-activation-link :focus {
  outline: 2 px #004d9d;
}
.esim-wrapper .form-element label {
  font-weight: 600;
}
fieldset {
  padding: 0 !important;
  width: 100%;
}
.img-responsive {
  border: 1px solid #dadada;
}
.full-width-text {
  width: 800px;
  height: 15px;
  word-break: break-all;
}
.center {
  text-align: center;
}
.mrs {
  margin-right: 5px !important;
}
.mts {
  margin-top: 5px !important;
}
.mtsm {
  margin-top: 8px !important;
}
.mt2l {
  margin-top: 16px !important;
}
.mtm {
  margin-top: 10px !important;
}
.mtl {
  margin-top: 15px !important;
}
.mtxl {
  margin-top: 20px !important;
}
.mtxxl {
  margin-top: 25px !important;
}
.mbs {
  margin-bottom: 5px !important;
}
.mbsm {
  margin-bottom: 8px !important;
}
.mbm {
  margin-bottom: 10px !important;
}
.mbl {
  margin-bottom: 15px !important;
}
.mbxl {
  margin-bottom: 20px !important;
}
.mbxxl {
  margin-bottom: 25px !important;
}
.mb-3xl {
  margin-bottom: 32px !important;
}
.mb4xl {
  margin-bottom: 40px !important;
}
.mt-4xl {
  margin-top: 32px !important;
}
.mt-5xl {
  margin-top: 40px !important;
}
.pdb-0 {
  padding-bottom: 0 !important;
}
.w-100 {
  width: 100% !important;
}
.form-element input::-moz-placeholder {
  color: #333333 !important;
  opacity: 1;
}
.form-element input:-ms-input-placeholder {
  color: #333333 !important;
}
.form-element input::-webkit-input-placeholder {
  color: #333333 !important;
}
.standard.row h2 {
  margin-bottom: 10px !important;
}
@media only screen and (max-width: 767px) {
  .col-wrapper {
    padding: 5px !important;
  }
}
.t-right {
  text-align: right;
}
a {
  text-decoration: underline;
}
.hide-in-mobile {
  display: none;
}
@media (min-width: 992px) {
  .hide-in-mobile {
    display: block;
  }
}
.cta-standard-secondary {
  height: 48px !important;
  width: 95px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-secondary {
    float: unset !important;
    width: 86% !important;
    text-align: center;
    padding-left: 20px;
  }
}
.cta-standard {
  height: 48px !important;
  width: 113px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard {
    float: unset !important;
    width: 86% !important;
    text-align: center;
  }
}
.cta-standard-cancel {
  height: 48px;
  width: 130px;
}
.cta-standard-landing {
  height: 48px;
  width: 135px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-landing {
    float: unset !important;
    width: 86% !important;
    text-align: center;
  }
}
.cta-standard-welcome {
  height: 48px;
  width: 135px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .cta-standard-welcome {
    float: unset !important;
    width: 55% !important;
  }
}
@media only screen and (max-width: 767px) {
  .close:after {
    padding-top: 0px !important;
    padding-left: 35px !important;
  }
}
@media only screen and (max-width: 767px) {
  #spectrum,
  #shade {
    display: none !important;
  }
}
.validation-error {
  margin-bottom: 10px;
  display: inline-block;
}
.btn:after {
  padding-top: 2px !important;
}
[tabindex]:focus:not(:hover),
a:focus:not(:hover),
button.mt-button:focus:not(:hover) {
  outline: none !important;
  background: none !important;
}
.breakWord {
  word-break: break-word !important;
}
.ppa-card {
  box-shadow: 0 3px 6px 1px #00000029;
  display: block;
  position: relative;
  z-index: 11;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  padding: 1rem;
  text-align: left;
  text-decoration: none;
  color: #414141;
}
.modal-top-shadow header {
  box-shadow: inset 0px 4px 0px 0px #0064d2;
}
:root,
:before,
:after {
  --accentPrimary: #ff4300;
  --accentSecondary: #ff4300;
  --accentTertiary: #ff4300;
  --accentPromotion: #ff4300;
  --accentBackgroundCallOut: #f4f4f4;
  --chatBubbleAgent: #e8e8e8;
  --switchOn: #008a00;
  --navigationBarBackground: #141414;
  --navigationBarForeground: #ffffff;
  --navigationBarTint: #ffffff;
  --navigationBarInteractiveForeground: #ffffff;
  --tabBarForegroundActive: #141414;
  --notificationBadge: #de2405;
  --spinnerPrimary: #141414;
  --spinnerSecondary: #141414;
  --dataVisForegroundPrimary: #ff4300;
  --dataVisForegroundSecondary: #141414;
  --dataVisForegroundNegative: #de2405;
  --dataVisForegroundUsage: #ff4300;
  --dataVisBackgroundUsageContainer: #141414;
  --iconBase: #141414;
  --illustrationStrokePrimary: #141414;
  --illustrationShadePrimary: #ff7b4c;
  --lozengeNegative: #de2405;
  --lozengeNegativeBackground: #fdf2f0;
  --lozengeTier: #0065ff;
  --lozengeTierBackground: #f0f6ff;
  --lozengeFeatured: #ff7b4c;
  --materialBaseTertiary: #ff7b4c;
  --materialBaseBrandPrimary: #ff4300;
  --materialBaseBrandSecondary: #141414;
  --materialBaseBrandTertiary: #ff4300;
  --materialBaseBrandQuaternary: #141414;
  --textBase: #141414;
  --textBrandTertiary: #db2a00;
  --utilityNegative: #de2405;
  --utilityNegativeBackground: #fdf2f0;
  --interactiveForegroundNormal: #141414;
  --interactiveForegroundActive: #282828;
  --destructiveForegroundNormal: #de2405;
  --neutralForegroundNormal: #141414;
  --interactiveBackgroundNormalAlternative: rgba(0, 0, 0, 0.04);
  --interactiveBackgroundHover: rgba(0, 0, 0, 0.04);
  --interactiveBackgroundHoverSolid: #f5f5f5;
  --interactiveBackgroundActive: rgba(0, 0, 0, 0.16);
  --interactiveBackgroundActiveSolid: #d6d6d6;
  --destructiveBackgroundHover: rgba(222, 36, 5, 0.06);
  --destructiveBackgroundActive: rgba(222, 36, 5, 0.12);
  --interactiveInverseBackgroundNormal: #141414;
  --interactiveInverseBackgroundActive: #282828;
  --destructiveInverseBackgroundNormal: #de2405;
  --neutralInverseBackgroundNormal: #141414;
  --neutralInverseBackgroundActive: #282828;
  --borderActive: #141414;
  --borderError: #de2405;
  --boxBackgroundHover: rgba(0, 0, 0, 0.04);
  --boxBackgroundHoverSolid: #f5f5f5;
  --boxBackgroundErrorHover: rgba(222, 36, 5, 0.06);
  --indicatorNormal: #141414;
  --indicatorError: #de2405;
  --fixedBlack: #141414;
  --hcmBlack: #141414;
}
.able-boost-radio label {
  z-index: 0 !important;
}
.able-CheckboxGroup--Comfortable div label,
.able-Checkbox--Comfortable--Picto label {
  z-index: 0;
}
.able-ActionButton {
  font-family: Roboto-Bold, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.able-Modal-Content--Comfortable__content {
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.able-Modal-Content--Comfortable__content header h2,
.able-Modal-Content--Comfortable__content header #ModalContent-heading {
  font-family: Roboto-Bold, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
@media screen and (max-width: 47.99rem) {
  .able-Modal-Content--Comfortable__content {
    height: auto !important;
    border-radius: 0.5rem !important;
    width: 90% !important;
  }
}
.able-text-style {
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.able-text-style[class*=able-text-style--Heading],
.able-text-style[class*=able-text-style--Label] {
  font-family: Roboto-Bold, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
.able-picto.able-boost-picto .shadeprimary {
  fill: var(--illustrationShadePrimary);
}
.able-picto.able-boost-picto .shadeprimary path {
  fill: var(--illustrationShadePrimary);
}
.able-picto.able-boost-picto .strokeprimary {
  stroke: var(--illustrationStrokePrimary);
}
.able-picto.able-boost-picto .strokeprimary path,
.able-picto.able-boost-picto .strokeprimary circle {
  stroke: var(--illustrationStrokePrimary);
}
.able-picto.able-boost-picto .strokesecondary {
  stroke: var(--illustrationStrokePrimary);
}
.able-picto.able-boost-picto .strokesecondary path {
  stroke: var(--illustrationStrokePrimary);
}
[class*=able-MessageSection] h2,
[class*=able-MessageSection] button,
[class*=able-RadioGroup] legend,
[class^=able-Checkbox--Comfortable] label,
.able-CheckboxGroup--Comfortable legend,
.able-Select label,
.able-TextField label {
  font-family: Roboto-Bold, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
[class*=able-MessageSection] p,
[class*=able-MessageInline] p,
[class*=able-MessageGlobal] p,
[class*=able-RadioGroup] label,
[class*=able-RadioGroup] legend span,
.able-CheckboxGroup--Comfortable legend span,
.able-CheckboxGroup--Comfortable label span,
[class^=able-Checkbox--Comfortable] label:not(:has(>span)),
[class^=able-Checkbox--Comfortable] label span,
.able-Checkbox label,
.able-Select select,
.able-Select option,
.able-TextField span,
.able-TextField p,
.able-TextField input {
  font-family: Roboto-Regular, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}
