@media only screen and (min-width: 48rem) {
  .confirm-block .row {
    margin-left: 20px;
  }
}
.confirm-block .esim-download-btn {
  margin-top: 2rem;
  width: 100%;
}
@media only screen and (min-width: 48rem) {
  .confirm-block .esim-download-btn {
    max-width: 360px;
  }
}
.confirm-block .ag-title-section {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.confirm-block .ag-title-section .able-picto {
  margin-bottom: 2rem;
}
.confirm-block .ag-title-section h1 {
  margin-bottom: 0.5rem;
}
.confirm-block .ag-content {
  padding-bottom: 5rem;
}
.confirm-block .ag-content .ag-checklist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin: 0;
  margin-block-start: 0;
  list-style-type: none;
  padding-inline-start: 0;
}
.confirm-block .ag-content .ag-checklist--item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
}
.confirm-block .ag-content .ag-checklist--item svg {
  margin: 0;
}
.confirm-block .ag-content .ag-inprogress {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.confirm-block .ag-content .ag-service-details {
  padding: 1.5rem;
  border: 1px solid #0000001a;
  border-left: 4px solid var(--accentPromotion);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}
.confirm-block .ag-content .ag-service-details--top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
}
.confirm-block .ag-content .ag-service-details .able-Divider {
  align-self: stretch;
}
.confirm-block .ag-content .ag-service-details--bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.confirm-block .ag-content .ag-finalsteps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
.confirm-block .ag-content .ag-finalsteps--step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.confirm-block .ag-content .ag-finalsteps--step img {
  height: 54px;
  width: 54px;
  padding: 2px;
}
.confirm-block .ag-content .ag-arenabled-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.confirm-block .ag-content .ag-arenabled-card--features {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.confirm-block .ag-content .offer-card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
}
.confirm-block .ag-content .offer-card-details .able-Divider {
  align-self: stretch;
}
.confirm-block .ag-content .download-card {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
  background-color: var(--white);
}
.confirm-block .ag-content .download-card .download-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.confirm-block .ag-content .download-card .download-content--top {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.confirm-block .ag-content .download-card .download-content--top img {
  height: 56px;
  width: 56px;
}
.confirm-block .ag-content .download-card .download-content--image img {
  width: 100%;
}
.confirm-block .ag-content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
@media only screen and (min-width: 48rem) {
  .confirm-block .ag-content__left .offer-card-details {
    display: none;
  }
}
@media only screen and (min-width: 48rem) {
  .confirm-block .ag-content__left {
    max-width: 360px;
  }
}
.confirm-block .ag-content__right .ag-service-details,
.confirm-block .ag-content__right .plan-details-esim {
  display: none;
}
@media only screen and (min-width: 48rem) {
  .confirm-block .ag-content__right .ag-service-details,
  .confirm-block .ag-content__right .plan-details-esim {
    max-width: 360px;
    margin-left: auto !important;
    display: flex;
  }
}
.confirm-block .ag-content .service-activation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.confirm-block .ag-content .service-activation-wrapper.change-order {
  flex-direction: column-reverse;
}
.confirm-block .esim-profile-error-guide .error-guide-section {
  max-width: 360px;
}
@media only screen and (min-width: 799px) {
  .confirm-block .esim-profile-error-guide .error-guide-section--right {
    margin-left: auto;
    margin-right: auto;
  }
}
.confirm-block .esim-profile-error-guide .esim-error-step-image img {
  height: auto;
  width: 100%;
}
